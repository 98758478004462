import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationBa from "../locales/ba/translation.json"
import translationDe from "../locales/de/translation.json"

const resources = {
  ba: {
    translation: translationBa,
  },
  de: {
    translation: translationDe,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "de",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
})

export default i18n
